import * as FirebaseAnalytics from 'firebase/analytics'
import * as FirebaseApp from 'firebase/app'
import * as FirebaseAppCheck from 'firebase/app-check'
import * as FirebaseAuth from 'firebase/auth'
import * as FirebaseFirestore from 'firebase/firestore'
import * as FirebaseFunctions from 'firebase/functions'
import { getRemoteConfig } from 'firebase/remote-config'
import * as FirebaseStorage from 'firebase/storage'
import initializeSentry from './initialize-sentry.js'

/*
 * Return configuration object for Firebase
 * @sig getFirebaseConfiguration :: () -> Object
 */
const getFirebase = () => {
    const { hostname } = new URL(window.location.href)

    const hostnameMismatch = !hostname.match(/production-range-io.web.app|app.range.io/)
    if (typeof window === 'undefined' || hostnameMismatch) throw new Error(`Can't start`)

    return FirebaseApp.initializeApp({
        apiKey: 'AIzaSyBCFzCC_6t4ltyUtTC7cUkKTbYwGH37aCo',
        appId: '1:229130128761:web:1f87882123fe70019973a8',
        authDomain: 'production-range-io.firebaseapp.com',
        projectId: 'production-range-io',
        storageBucket: 'production-range-io.appspot.com',
        messagingSenderId: '229130128761',
        measurementId: 'G-B66M7JBS6Q',
    })
}

// Initialize Firebase
const app = getFirebase()
const auth = FirebaseAuth.getAuth(app)
const storage = FirebaseStorage.getStorage(app)
const firestore = FirebaseFirestore.getFirestore(app)
const remoteConfig = getRemoteConfig(app)
const functions = FirebaseFunctions.getFunctions(app, 'us-west2')

initializeSentry('production')

// clunky way to initialize analytics that will (eventually) make it available outside this module
let analytics = null
FirebaseAnalytics.isSupported().then(yes => (analytics = yes ? FirebaseAnalytics.getAnalytics(app) : null))

const appCheck = FirebaseAppCheck.initializeAppCheck(app, {
    provider: new FirebaseAppCheck.ReCaptchaV3Provider('6Levty0kAAAAAK1gBiyv_x0gE3YyMHpTldquucu3'),
    isTokenAutoRefreshEnabled: true,
})

const KNOCK_PUBLIC_API_KEY = 'pk_fy0Ll3w-pODg05EhBkQZcK9eEfVxkwlLBhjXh2_i5Xs'
const STRIPE_PUBLISHABLE_KEY =
    'pk_live_51JwfmLB0VhUzDU5ZelOdI155Kx4EBDsSogRR3yJJRU54BSDEp5TGxop64PzMOAMA093xj4Ujo4eZdZaRTovotOjs00fokc8Q18'

export {
    app,
    auth,
    firestore,
    storage,
    analytics,
    appCheck,
    remoteConfig,
    functions,
    KNOCK_PUBLIC_API_KEY,
    STRIPE_PUBLISHABLE_KEY,
}
