/*
 * Command to consolidate all the work that has to happen when a change occurs to the selected
 * collaboration, canvas or canvasSource along with changes to mapPositions
 *
 * These "subcommands" are implemented to consolidate code that used to be scattered in many places:
 *
 *   canvasSourcesChanged
 *   navigateToCollaboration
 *   projectWasLoaded
 *   selectedCanvasChanged
 *   urlChanged
 *   userMovedMap
 *
 * A specific subcommans is specified by passing a "context" to SelectionChanged
 * The function selectionChanged is a convenience for invoking the command
 */

import { CanvasSource, NavigatorFilterSettings } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { mergeRight, tagged } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import { resources } from './resources.js'

const contexts = [
    'canvasSourcesChanged',
    'navigateToCollaboration',
    'projectWasLoaded',
    'selectedCanvasChanged',
    'urlChanged',
    'userMovedMap',
    'foregroundMapLoaded',
    'backgroundMapLoaded',
].join('|')

/*
 * Data passed to the SelectionChanged command
 */
const SelectionChanged = tagged('SelectionChanged', {
    canvasId: StringTypes.OptionalId,
    collaborationId: StringTypes.OptionalId,
    lng: 'Number?',
    lat: 'Number?',
    zoom: 'Number?',
    context: new RegExp(contexts),
})

/*
 * run
 * @sig selectionChanged :: (Resources, SelectionChanged) -> void
 */
SelectionChanged.run = (resources, selectedCanvasChangedCommand) => {
    // When a project has finished loading, load data, set selections and set the current position
    const processProjectWasLoaded = () => {
        const state = getState()

        const { mapPositions, firstCanvas, firstCanvasSource } = ReduxSelectors.initialDataForSelectedProject(state)
        const { center, zoom } = mapPositions[firstCanvas.id]

        dispatch(ReduxActions.selectedCanvasChanged(firstCanvas.id))
        dispatch(ReduxActions.selectedCanvasSourceChanged(firstCanvasSource.id))
        dispatch(ReduxActions.mapPositionsHistoryInitialized(mapPositions))
        dispatch(ReduxActions.mapMoved({ ...center, zoom, context }))
    }

    // if there is data in the URL (a deep link) update the selections and the current position from the url
    // should be called AFTER processProjectWasLoaded
    const processUrlChanged = () => {
        const { canvasId, collaborationId, lng, lat, zoom } = selectedCanvasChangedCommand

        if (canvasId) {
            dispatch(ReduxActions.selectedCanvasChanged(canvasId))
            dispatch(ReduxActions.possiblySelectFirstCanvasSource())
        }

        if (collaborationId) dispatch(ReduxActions.collaborationSelected({ id: collaborationId }))
        if (zoom) dispatch(ReduxActions.mapMoved({ lng, lat, zoom, context }))
    }

    // the user moved the map (as opposed to programmatic changes to the position)
    const processUserMovedMap = () => {
        const { lng, lat, zoom } = selectedCanvasChangedCommand
        dispatch(ReduxActions.mapMoved({ lng, lat, zoom, context }))
    }

    const setMaxAndMinZoom = () => {
        const canvasSource = ReduxSelectors.selectedCanvasSource(getState())
        const maxZoom = CanvasSource.getMaxZoom(canvasSource)
        const minZoom = CanvasSource.getMinZoom(canvasSource)
        if (foregroundMapboxMap) {
            foregroundMapboxMap.setMaxZoom(maxZoom)
            foregroundMapboxMap.setMinZoom(minZoom)
        }
        if (backgroundMapboxMap) {
            backgroundMapboxMap.setMaxZoom(maxZoom)
            backgroundMapboxMap.setMinZoom(minZoom)
        }
    }

    // the user switched canvases
    const processSelectedCanvasChanged = () => {
        const { canvasId } = selectedCanvasChangedCommand
        dispatch(ReduxActions.selectedCanvasChanged(canvasId))
        dispatch(ReduxActions.possiblySelectFirstCanvasSource())

        if (!foregroundMapboxMap) return // true on task list / media view

        const mapPosition = ReduxSelectors.mapPosition(getState())
        foregroundMapboxMap.jumpTo(
            { zoom: mapPosition.zoom, center: mapPosition.center },
            { dontSendSelectionChange: true } // no need to send it back around to redux later
        )

        setMaxAndMinZoom()
    }

    // show a collaboration on the task list or media view on the canvas view when the user clicks "view on canvas"
    const processNavigateToCollaboration = () => {
        const { collaborationId } = selectedCanvasChangedCommand
        const state = getState()

        const organizationId = ReduxSelectors.selectedOrganizationId(state)
        const projectId = ReduxSelectors.selectedProjectId(state)
        const collaboration = ReduxSelectors.collaborationWithId(state, collaborationId)
        const canvas = ReduxSelectors.canvasForCollaboration(state, collaboration)
        const geometry = ReduxSelectors.geometryForCollaboration(state, collaboration)
        const { zoom } = ReduxSelectors.mapPosition(state)
        dispatch(ReduxActions.collaborationSelected({ id: collaborationId }))

        const [lng, lat] = geometry.coordinates
        dispatch(ReduxActions.mapMoved({ lng, lat, zoom, context: 'navigateToCollaboration' }))

        // if the geometry was archived, then make sure canvas view will be showing it
        if (geometry.archivedDate) {
            const navigatorFilterSettings = ReduxSelectors.navigatorFilterSettings(getState())
            dispatch(
                ReduxActions.navigatorFilterSettingsChanged(
                    NavigatorFilterSettings.setShowArchivedPins(navigatorFilterSettings, true)
                )
            )
        }

        navigate(`/${organizationId}/${projectId}/${canvas.id}`)
    }

    // an admin updated the PDF sources
    const processCanvasSourcesChanged = () => {
        const { canvasId } = selectedCanvasChangedCommand

        const state = getState()
        const organizationId = ReduxSelectors.selectedOrganizationId(state)
        const project = ReduxSelectors.selectedProject(state)
        dispatch(ReduxActions.updateDataForChangedCanvases())
        dispatch(ReduxActions.selectedCanvasChanged(canvasId))
        dispatch(ReduxActions.possiblySelectFirstCanvasSource())
        navigate(`/${organizationId}/${project.id}`)
    }

    const processForegroundMapLoaded = () => {
        setMaxAndMinZoom()

        // force the map to redraw (if we've come from the task list or media tabs)
        foregroundMapboxMap.jumpTo({ ...foregroundMapboxMap.getCenter() })
    }

    const processBackgroundMapLoaded = () => setMaxAndMinZoom()

    const { context } = selectedCanvasChangedCommand
    const { dispatch, getState, foregroundMapboxMap, backgroundMapboxMap, navigate } = resources

    if (context === 'projectWasLoaded') processProjectWasLoaded()
    else if (context === 'selectedCanvasChanged') processSelectedCanvasChanged()
    else if (context === 'userMovedMap') processUserMovedMap()
    else if (context === 'urlChanged') processUrlChanged()
    else if (context === 'navigateToCollaboration') processNavigateToCollaboration()
    else if (context === 'canvasSourcesChanged') processCanvasSourcesChanged()
    else if (context === 'foregroundMapLoaded') processForegroundMapLoaded()
    else if (context === 'backgroundMapLoaded') processBackgroundMapLoaded()
    else throw new Error(`Don't understand context ${context}`)
}

/*
 * Public API (impure: collects resources)
 */
const selectionChanged = (props, extraResources = {}) =>
    SelectionChanged.run(mergeRight(resources(), extraResources), SelectionChanged.from(props))

export default selectionChanged
