import { Collaboration, MediaViewFilterSettings, Upload } from '@range.io/basic-types'
import { arrayToLookupTable, filterValues, groupBy, mergeRight, pluck, uniq, without } from '@range.io/functional'
import { format, isSameDay, subDays } from 'date-fns'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getImageUrl, URL_SEARCH_ORDER } from '../components-reusable/hooks/useImageUrl.js'
import { useNavigateToCollaboration } from '../components-reusable/hooks/useNavigateToCollaboration.js'
import { Button, Flex, FlexColumn, FlexRow, Icon, ScrollableList, Text, Tooltip } from '../components-reusable/index.js'
import { UploadChangedCommand, UploadRemovedCommand } from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { downloadAndSaveToZip, downloadImage } from '../helpers.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import * as Segment from '../segment/segment.js'
import Identifier from './Identifier.js'
import MediaViewBulkActions from './MediaViewBulkActions.js'
import MediaViewHeader from './MediaViewHeader.js'
import MediaViewItem from './MediaViewItem.js'
import { PageLoadingMedia } from './PageLoading.js'
import ProjectPage, { PageContent } from './ProjectPage.js'
import ControlBar from './ControlBar.js'
import { v4 } from 'uuid'

const StyledMediaViewContent = styled(FlexColumn, {
    background: '$neutral10',
    height: '100%',
    borderRadius: '6px',
    border: '1px solid $neutral07',
})

const GridLayout = styled('div', {
    minWidth: '314px',
    margin: '0 auto',
    display: 'grid',
    gap: '24px',
    'grid-template-columns': 'repeat(auto-fill, minmax(314px, 1fr))',
    justifyItems: 'center',
})

const DaySelectedIndicatorWrapper = styled(FlexColumn, {
    width: '32px',
    height: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    background: '$neutral09',
    transitionDuration: '0.4s',
    variants: {
        isSelected: {
            true: {
                color: '#FFFFFF',
                background: '$primary04',
            },
            false: { border: '1px solid $neutral07' },
        },
    },
    '&:hover': {
        border: '1px solid $primary04',
        cursor: 'pointer',
    },
})

const DaySelectedIndicator = ({ isSelected = false, onClick }) => {
    const handleClick = e => {
        e.stopPropagation()
        onClick()
    }

    return (
        <DaySelectedIndicatorWrapper isSelected={isSelected} onClick={handleClick}>
            {isSelected && <Icon name="tickSmall" iconSize="12px" />}
        </DaySelectedIndicatorWrapper>
    )
}

const MediaViewDaySectionHeader = styled(FlexRow, {
    color: '$neutral04',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '21px',
    marginBottom: '24px',
    marginTop: '24px',
    gap: '10px',
    alignItems: 'center',

    [`& ${DaySelectedIndicatorWrapper}`]: {},
})

/*
 * Given a date index string (year-month-day) return a formatted string for that date
 * @sig formatDaySectionHeaderDate :: (String) -> String
 */
const formatDaySectionHeaderDate = dateIndex => {
    if (window.redactTimes) return 'redacted date/time'

    const [year, monthIndex, day] = dateIndex.split('-')
    const inDate = new Date(year, monthIndex, day)
    const now = new Date()
    const baseFormatted = format(inDate, 'EEE, MMM d, y')
    if (isSameDay(inDate, now)) return `Today • ${baseFormatted}`
    if (isSameDay(inDate, subDays(now, 1))) return `Yesterday • ${baseFormatted}`
    return baseFormatted
}

const MediaViewEmptyContent = ({ hasFiltersApplied, onGoToCanvas }) => (
    <FlexColumn css={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <Flex css={{ zIndex: 999, position: 'relative' }}>
            <Flex
                css={{
                    width: 14,
                    height: 1,
                    position: 'absolute',
                    filter: 'blur(4px)',
                    background: '$neutral01',
                    mt: 100,
                }}
            />
            <Icon name="pin" css={{ color: '$neutral07', width: 64, mb: 12, position: 'relative' }} />
            <Icon name="image" css={{ color: '$neutral04', width: 32, position: 'absolute', pt: 16 }} />
        </Flex>
        <Text css={{ color: '$neutral04', fs: '18px', fw: '600', lh: '24px', mb: '8px', mt: 8, zIndex: 999 }}>
            {hasFiltersApplied ? `It looks like there's no results ...🤔` : `It looks like there's no media...🤔`}
        </Text>
        <Text css={{ color: '$neutral05', fs: '14px', fw: '500', lh: '20px', mb: '24px', zIndex: 999 }}>
            {hasFiltersApplied
                ? `Try adjusting, or clearing your filters`
                : `Add photos and documents to pins on the canvas`}
        </Text>
        {hasFiltersApplied ? (
            <Flex />
        ) : (
            <Button css={{ zIndex: 999 }} variant="primary" type="submit" size="lg" onClick={onGoToCanvas}>
                <Icon name="levels" iconSize="16px" />
                <Text>Go to Canvas</Text>
            </Button>
        )}
    </FlexColumn>
)

const DateSection = React.memo(
    ({
        index,
        uploadsGrouped,
        dateIndex,
        checkIfAllUploadsAreSelected,
        handleAllUploadInDateSelected,
        selectedMediaUploadsIds,
        project,
        collaborationShapes,
        handleUploadClicked,
        handleUploadDelete,
        downloadImageForUpload,
        handleUploadLocationClicked,
        handleUploadSelected,
    }) => {
        const datedUploads = uploadsGrouped[dateIndex]

        const areAllDateUploadsSelected = checkIfAllUploadsAreSelected(datedUploads)
        return (
            <div key={index}>
                <MediaViewDaySectionHeader>
                    <Tooltip tooltipText="Select all" side="bottom" align="center">
                        <FlexRow>
                            <DaySelectedIndicator
                                isSelected={areAllDateUploadsSelected}
                                onClick={() => handleAllUploadInDateSelected(dateIndex)}
                            />
                        </FlexRow>
                    </Tooltip>
                    {`${formatDaySectionHeaderDate(dateIndex)} • ${
                        datedUploads.length === 1 ? '1 photo' : `${datedUploads.length} photos`
                    }`}
                </MediaViewDaySectionHeader>
                <GridLayout>
                    {datedUploads.map(upload => {
                        const identifierComponent = upload?.collaboration?.identifier && (
                            <FlexRow>
                                <Text css={{ fg: '$neutral05', fs: 12, fw: 500 }}>(</Text>
                                <Identifier
                                    projectIdentifier={project.identifier}
                                    collaborationIdentifier={upload?.collaboration?.identifier}
                                    variant="small"
                                />
                                <Text css={{ fg: '$neutral05', fs: 12, fw: 500 }}>)</Text>
                            </FlexRow>
                        )
                        const isSelected = Boolean(
                            selectedMediaUploadsIds.length && selectedMediaUploadsIds.indexOf(upload.id) !== -1
                        )

                        return (
                            <MediaViewItem
                                key={upload.id}
                                upload={upload}
                                {...upload}
                                collaborationName={upload?.collaboration?.name}
                                isSelected={isSelected}
                                onClick={() => handleUploadClicked(upload.id)}
                                onDeleteClick={() => handleUploadDelete(upload)}
                                onDownloadClick={() => downloadImageForUpload(upload)}
                                onLocationClick={() => handleUploadLocationClicked(upload.id)}
                                onSelectClick={() => handleUploadSelected(upload.id)}
                                identifierComponent={identifierComponent}
                            />
                        )
                    })}
                </GridLayout>
                {/* Manually adding padding down the bottom of the grid container so the last row doesn't sit immediately against the bottom edge */}
                <Flex css={{ width: '100%', height: 24 }} />
            </div>
        )
    }
)

const MediaView = () => {
    const { getState } = useStore()
    const dispatch = useDispatch()
    const allUploads = useSelector(ReduxSelectors.uploadLookupTable)
    const allCollaborations = useSelector(ReduxSelectors.collaborationLookupTable)
    const allCanvases = useSelector(ReduxSelectors.canvasLookupTable)
    const navigate = useNavigate()
    const { workspaceId, projectId } = useParams()
    const [uploadsGrouped, setUploadsGrouped] = useState({})
    const { runCommand } = useCommandHistory()
    const viewportRef = useRef()
    const { navigateToCollaboration } = useNavigateToCollaboration()
    const [mediaCount, setMediaCount] = useState({ document: 0, photo: 0 })
    const project = useSelector(ReduxSelectors.selectedProject)
    const currentUserId = ReduxSelectors.selectedUserId(getState())
    const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)
    const user = selectedOrganization?.participants[currentUserId]

    const { collaborations: collaborationShapes } = ReduxSelectors.allShapes(getState())
    const selectedMediaUploadsIds = useSelector(ReduxSelectors.selectedMediaUploadsIds)
    const filterSettings = useSelector(ReduxSelectors.mediaViewFilterSettings)
    const filteredMediaItems = useSelector(ReduxSelectors.filteredMediaView)

    const formatUpload = upload => {
        const state = getState()
        const participantShape = ReduxSelectors.selectedProjectParticipantShapeWithId(state, upload.createdBy)
        const parentCollaboration = allCollaborations[upload.parentId]
        const geometry = ReduxSelectors.geometryForCollaboration(state, parentCollaboration)
        const canvas = allCanvases[geometry.canvasId]
        const isTask = Collaboration.isTask(parentCollaboration)
        const mapPinType = isTask ? 'task' : 'photo'
        const collaboration = ReduxSelectors.collaborationForUpload(state, upload)
        const correlatedUploads = ReduxSelectors.correlatedUploads(state, upload)
        const version = correlatedUploads.length > 1 ? correlatedUploads.indexOf(upload) : null // only add versioning if there is more than one correlated upload
        const commentsCount = ReduxSelectors.commentShapesForUpload(state, upload)?.length

        return mergeRight(upload, {
            canvasId: canvas.id,
            collaboration,
            collaborationTagNames: ReduxSelectors.textsFromTagNameIds(state, parentCollaboration.tags),
            locationCoordinates: geometry.coordinates,
            locationLabel: canvas.name,
            mapPinType,
            name: Upload.isImage(upload) ? undefined : upload.name, // names for images are useless here
            tagNames: ReduxSelectors.textsFromTagNameIds(state, upload.tagIds),
            participantShape,
            version,
            commentsCount,
        })
    }

    const updateMediaCount = uploads => {
        const clearMediaCount = { photo: 0, document: 0 }
        const newMediaCount = Object.values(uploads).reduce((acc, u) => {
            return Upload.isImage(u) ? { ...acc, photo: acc.photo + 1 } : { ...acc, document: acc.document + 1 }
        }, clearMediaCount)
        setMediaCount(newMediaCount)
    }

    const updateGroupedUploads = sourceUploads => {
        // get formatted uploads to expected structure
        const formattedUploads = Object.values(sourceUploads).map(formatUpload)
        const safeFormatInteger = integer => (integer < 10 ? `0${integer}` : integer)
        // group uploads by it's keyed date 'year-month-day', zero escape month and day for better sorting
        const updatedGroupedUploads = groupBy(
            u =>
                `${u.createdAt.getFullYear()}-${safeFormatInteger(u.createdAt.getMonth())}-${safeFormatInteger(
                    u.createdAt.getDate()
                )}`,
            formattedUploads
        )
        // sort each group by date to make newer uploads appear first in each group too
        for (const groupKey in updatedGroupedUploads)
            updatedGroupedUploads[groupKey] = updatedGroupedUploads[groupKey].sort(
                (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
            )
        setUploadsGrouped(updatedGroupedUploads)
    }

    useEffect(() => {
        updateMediaCount(filteredMediaItems)
        const filteredByType = filterValues(upload => {
            if (filterSettings.fileType === 'photo') return Upload.isImage(upload)
            if (filterSettings.fileType === 'document') return !Upload.isImage(upload)
            return true
        }, filteredMediaItems)
        updateGroupedUploads(arrayToLookupTable('id', filteredByType))
    }, [filteredMediaItems])

    const setFilterSettings = settings => {
        dispatch(ReduxActions.mediaViewFilterSettingsChanged(settings))
    }
    const resetFilterSettings = () => setFilterSettings(MediaViewFilterSettings.defaultFilterSettings())

    // abbreviate a call to FilterSettings[func]
    const updateFilter = func => args => setFilterSettings(func(filterSettings, args))

    const handleUploadSelected = uploadId => {
        dispatch(ReduxActions.selectedMediaUploadsIdToggled({ id: uploadId }))
    }

    const handleAllUploadInDateSelected = dateIndex => {
        const datedUploads = uploadsGrouped[dateIndex]

        const areAllUploadsSelected = checkIfAllUploadsAreSelected(datedUploads)
        let newSelectedUploads = []
        const idsOfDateUploads = pluck('id', datedUploads)
        const selectedIds = selectedMediaUploadsIds || []

        // if they are all already selected, toggle all off
        if (areAllUploadsSelected) newSelectedUploads = without(idsOfDateUploads, selectedMediaUploadsIds)
        // if they are not all selected, toggle on the remaining ones
        else newSelectedUploads = uniq([...idsOfDateUploads, ...selectedIds])

        dispatch(ReduxActions.selectedMediaUploadsChanged({ ids: newSelectedUploads }))
    }

    const toggleAllGroupUploadsSelected = areAllGroupUploadsSelected => {
        if (areAllGroupUploadsSelected) {
            const uploadsIds = pluck('id', Object.values(uploadsGrouped).flat())

            // pick only those media ids, that are not in the currently displayed group
            const uploadsIdsWithoutGroupOnes = without(uploadsIds, selectedMediaUploadsIds)
            dispatch(ReduxActions.selectedMediaUploadsChanged({ ids: uploadsIdsWithoutGroupOnes }))
        } else {
            const uploadsIds = pluck('id', Object.values(uploadsGrouped).flat())
            const newSelectedIds = uniq([...selectedMediaUploadsIds, ...uploadsIds])
            dispatch(ReduxActions.selectedMediaUploadsChanged({ ids: newSelectedIds }))
        }
    }

    const onUploadDelete = upload => runCommand(UploadRemovedCommand.Outbound(upload.id))

    const handleUploadDelete = upload => {
        const documentType = Upload.isImage(upload) ? 'photo' : 'document'
        const title = `Are you sure you want to delete this ${documentType}?`
        const description = `You cannot undo this action.`
        dispatch(
            ReduxActions.globalModalDataSet({
                type: 'destructive',
                title,
                description,
                cancelButton: {
                    label: 'Cancel',
                },
                submitButton: {
                    label: 'Delete',
                    onClick: () => onUploadDelete(upload),
                },
            })
        )
    }

    const getInitialScrollState = () => {
        const restoredScrollState = window.history.state?.usr?.restoreScrollState
        return restoredScrollState ? JSON.parse(restoredScrollState) : undefined
    }

    const checkIfAllUploadsAreSelected = uploads => {
        if (!selectedMediaUploadsIds.length) return false

        const uploadsIds = pluck('id', Object.values(uploads).flat())

        // if selectedMediaUploadsIds contain every id from uploadsIds
        // it means all uploads from this subgroup are selected
        return without(selectedMediaUploadsIds, uploadsIds).length === 0
    }

    const areAllDisplayedUploadsSelected = useMemo(() => {
        return checkIfAllUploadsAreSelected(uploadsGrouped)
    }, [selectedMediaUploadsIds, uploadsGrouped])

    const handleUploadClicked = uploadId => {
        // Virtuoso requires a callback when fetching scroll data. We need to wait for it before we navigate.
        const navigateToUploadDetails = scrollState => {
            const filteredUploadIds = Object.values(filteredMediaItems)
                .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                .map(u => u.id)
            navigate(uploadId, {
                state: {
                    safeToNavigateBack: true,
                    uploadIds: filteredUploadIds,
                    navigatedFrom: 'mediaView',
                    navigatedFromUrl: window.location.pathname,
                    scrollState: JSON.stringify(scrollState),
                },
            })
        }

        viewportRef.current.getState(navigateToUploadDetails)
    }

    const handleUploadLocationClicked = uploadId => {
        const clickedUpload = allUploads[uploadId]
        navigateToCollaboration(clickedUpload.parentId)
    }

    const downloadImageForUpload = async upload => {
        const uploadImageUrl = await getImageUrl(
            projectId,
            upload.id,
            URL_SEARCH_ORDER.ANNOTATED,
            upload.storageVersion
        )
        downloadImage(uploadImageUrl, Upload.fileName(upload))
    }

    const renderSectionForDate = index => {
        return (
            <DateSection
                uploadsGrouped={uploadsGrouped}
                index={index}
                dateIndex={index}
                checkIfAllUploadsAreSelected={checkIfAllUploadsAreSelected}
                handleAllUploadInDateSelected={handleAllUploadInDateSelected}
                selectedMediaUploadsIds={selectedMediaUploadsIds}
                project={project}
                collaborationShapes={collaborationShapes}
                handleUploadClicked={handleUploadClicked}
                handleUploadDelete={handleUploadDelete}
                downloadImageForUpload={downloadImageForUpload}
                handleUploadLocationClicked={handleUploadLocationClicked}
                handleUploadSelected={handleUploadSelected}
            />
        )
    }

    const unselectAllUploads = () => dispatch(ReduxActions.selectedMediaUploadsChanged({ ids: [] }))

    const downloadSelectedUploads = () => {
        const getUrlForUpload = async upload => {
            const imageUrl = await getImageUrl(projectId, upload.id, URL_SEARCH_ORDER.ANNOTATED, upload.storageVersion)
            return { name: Upload.fileName(upload), url: imageUrl }
        }
        const getUrlsForUploads = async uploads => Promise.all(uploads.map(getUrlForUpload))
        const filteredSelectedUploads = selectedMediaUploadsIds.map(id => allUploads[id])
        getUrlsForUploads(filteredSelectedUploads).then(uploadImageNamesAndUrls =>
            downloadAndSaveToZip('uploads.zip', uploadImageNamesAndUrls)
        )
    }

    const deleteSelectedUploads = () =>
        selectedMediaUploadsIds.forEach(uploadId => {
            runCommand(UploadRemovedCommand.Outbound(uploadId))
        })

    const _callDeleteModal = () => {
        let title = `Are you sure you want to delete the selected media (${selectedPhotoCount})?`

        const hasMultipleSelected = selectedMediaUploadsIds.length > 1
        if (!hasMultipleSelected) {
            const documentType = Upload.isImage(allUploads[selectedMediaUploadsIds[0]]) ? 'photo' : 'document'
            title = `Are you sure you want to delete this ${documentType}?`
        }
        const description = 'You cannot undo this action.'
        dispatch(
            ReduxActions.globalModalDataSet({
                type: 'destructive',
                title,
                description,
                confirmationLabel: hasMultipleSelected && 'Confirm you would like to delete these media items.',
                cancelButton: {
                    label: 'Cancel',
                },
                submitButton: {
                    label: 'Delete',
                    onClick: deleteSelectedUploads,
                },
            })
        )
    }

    const navigateToTagManager = () => {
        const projectPath = `/${workspaceId}/${projectId}`
        navigate(`${projectPath}/tags`, { state: { safeToNavigateBack: true } })
    }

    const addTagsToSelected = newTagIds => {
        selectedMediaUploadsIds.forEach(uploadId => {
            const newTags = uniq([...allUploads[uploadId].tagIds, ...newTagIds])
            runCommand(UploadChangedCommand.Outbound(uploadId, { tagIds: newTags }))
        })
    }

    const replaceTagsInSelected = newTagIds => {
        selectedMediaUploadsIds.forEach(uploadId => {
            runCommand(UploadChangedCommand.Outbound(uploadId, { tagIds: newTagIds }))
        })
    }

    const handleGoToProjectCanvas = () => navigate(`/${workspaceId}/${projectId}`)

    const renderContent = () => {
        const isLoaded = ReduxSelectors.isProjectDataLoaded(getState())
        if (!isLoaded) return <PageLoadingMedia />
        const isEmpty = Object.keys(uploadsGrouped).length === 0
        const hasFiltersApplied = areFiltersActive
        if (isEmpty)
            return (
                <MediaViewEmptyContent hasFiltersApplied={hasFiltersApplied} onGoToCanvas={handleGoToProjectCanvas} />
            )
        const sortedDateIndices = Object.keys(uploadsGrouped).sort((a, b) => b.localeCompare(a))
        const toggleType = areAllDisplayedUploadsSelected ? 'Unselect' : 'Select'
        const contentType = hasFiltersApplied ? 'filtered' : 'all'

        return (
            <ScrollableList
                css={{ padding: '0px 16px 0px 16px' }}
                data={sortedDateIndices}
                renderDataItem={renderSectionForDate}
                ref={viewportRef}
                restoreStateFrom={getInitialScrollState()}
            >
                <MediaViewDaySectionHeader css={{ marginBottom: '0' }}>
                    <Tooltip tooltipText={`${toggleType} ${contentType} media items`} side="bottom" align="center">
                        <FlexRow>
                            <DaySelectedIndicator
                                isSelected={areAllDisplayedUploadsSelected}
                                onClick={() => toggleAllGroupUploadsSelected(areAllDisplayedUploadsSelected)}
                            />
                        </FlexRow>
                    </Tooltip>
                    {toggleType} all
                </MediaViewDaySectionHeader>
            </ScrollableList>
        )
    }

    const hasRightsToDelete = React.useMemo(() => {
        if (!user || !allUploads) return false

        // check if current user is the author of all selected tasks
        const canManage = selectedMediaUploadsIds.every(uploadId =>
            ReduxSelectors.isDeleteAllowed('upload', allUploads[uploadId])(getState())
        )

        return currentUserId && canManage
    }, [selectedMediaUploadsIds, allUploads, currentUserId, user])

    const selectedPhotoCount = selectedMediaUploadsIds.length
    const areFiltersActive = !MediaViewFilterSettings.isSetToDefaults(filterSettings)

    useEffect(() => {
        if (project) {
            const segmentParams = ReduxSelectors.paramsForTrackEvent(getState())
            segmentParams.mediaCount = Object.keys(filteredMediaItems).length
            segmentParams.projectName = project.name
            Segment.sendTrack('project media viewed', v4(), segmentParams)
        }
    }, [project, filteredMediaItems])

    return (
        <ProjectPage data-cy="media-view" activeTab={ControlBar.TABS.MEDIA}>
            <PageContent>
                <StyledMediaViewContent>
                    <MediaViewHeader
                        mediaCount={mediaCount}
                        unselectAllUploads={unselectAllUploads}
                        updateFilter={updateFilter}
                        filter={MediaViewFilterSettings}
                        filterSettings={filterSettings}
                        resetFilters={resetFilterSettings}
                        areFiltersActive={areFiltersActive}
                    />
                    {selectedPhotoCount > 0 && (
                        <MediaViewBulkActions
                            onCancel={unselectAllUploads}
                            onDelete={_callDeleteModal}
                            onDownload={downloadSelectedUploads}
                            onGoToTagManager={navigateToTagManager}
                            onTagsAdd={addTagsToSelected}
                            onTagsReplace={replaceTagsInSelected}
                            selectedUploads={selectedMediaUploadsIds}
                            selectedPhotoCount={selectedPhotoCount}
                            hasRightsToDelete={hasRightsToDelete}
                            areFiltersActive={areFiltersActive}
                            selectedProject={project}
                        />
                    )}

                    {renderContent()}
                </StyledMediaViewContent>
            </PageContent>
        </ProjectPage>
    )
}

export default MediaView
